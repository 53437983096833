html{
    height: 100%;
    background-color: #0c1565;
}

body{
    height: 100%;
    background-color: #0c1565;
}

#app{
    height: 100%;
    display: flex;
}

.container{
    margin-bottom: auto !important;
    margin-top: auto !important;
}

#app > .container > .rounded {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #fff;
}

h6{
    line-height: 1.25 !important;
}